import {React, useState} from 'react'

import Nav from '../Nav'

const Contact = () => {
    const [errorMessage, setErrorMessage] = useState("");

    document.title = "Jonathan Pearson - Contact";
    
    const sendEmail = (event) => {
        event.preventDefault();

        const formData = new FormData(document.getElementById("contact_form")); //document.getElementById("email_form")

        console.log(...formData);

        const res = fetch('/api/email/send',
        {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            if(!response.ok) {
                throw new Error(response.status);
            }

            return response;
        })
        .then((response) => {
            setErrorMessage("Email Sent");
        })
        .catch((error) => {
            console.log(error);
            setErrorMessage(error.toString());
        })

    }

    return (
    <div>
        <Nav />

        <div className='p-5'>
            <div className='text-xl font-bold'>
                Contact
            </div>

            
            <div className={'font-bold duration-500 transition-all rounded-lg bg-blue-200 ' + (errorMessage !== "" ? "opacity-100 p-5 my-5" : "opacity-0")}>
                { errorMessage !== "" &&
                    <p>{errorMessage}</p>
                }
            </div>
            
            
            <form id='contact_form' className='mt-2' onSubmit={sendEmail}>
                <div>
                    <label className='font-bold'>Name</label>
                    <input name='name' className='border-2 p-2 rounded block w-full sm:w-96' type='text' />
                </div>

                <div>
                    <label className='mt-2 block font-bold'>Email</label>
                    <input name='fromAddress' className='border-2 p-2 rounded block w-full sm:w-96' type='email' />
                </div>

                <div>
                    <label className='font-bold'>Message</label>
                    <textarea name='message' class="block border-2 p-2 resize-y rounded-md w-full">
                        
                    </textarea>
                </div>

                <button className='bg-blue-500 hover:bg-blue-400 text-white rounded text-bold w-full sm:w-32 mt-5 py-2'>Send</button>
            </form>
        </div>
    </div>
  )
}

export default Contact