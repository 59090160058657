import React from 'react'

import { NavLink, useResolvedPath, useMatch} from 'react-router-dom'

const MenuLink = ({title, to}) => {
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({path: resolvedPath.pathname, end: true})

    return (
      <NavLink className={({isActive}) =>
        "block mt-2 sm:mt-0 sm:inline-block hover:text-white py-2 sm:py-5 px-2 mr-1 text-white " + (isActive ? "font-bold bg-blue-900" : "")
      } to={to}>{title}</NavLink>
  )
}

export default MenuLink