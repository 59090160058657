import React from 'react'

import Nav from './Nav'
import Footer from './Footer'

const Layout = (props) => {
  return (
    <div>
        <Nav />
        <div className='p-5'>

            { props.children }
            
        </div>
        <Footer />
    </div>
  )
}

export default Layout