import React from 'react'

import Nav from '../Nav'

const HardwareExperience = () => {

  return (
    <div>
        <Nav />

        <div className='p-5'>
            <h1>Hardware Projects</h1>
        </div>
        
    </div>
  )
}

export default HardwareExperience