import React from 'react'

const Footer = () => {
  return (
    <div className='p-5'>
        © Jonathan Pearson
    </div>
  )
}

export default Footer