import React from 'react'

const ProjectGallery = ({project, onSelectedImageChanged}) => {

  const onImageClick = (index) => {
    onSelectedImageChanged(project.id, index);
  }

  return (
    <div className='flex flex-col h-full w-full'>

    <img className='grow object-contain h-0 p-2' src={project.images[project.selectedImageIndex]} />

      <div className='flex flex-row rounded-b-lg bg-gray-200 pt-2 top-0 p-2 overflow-scroll'>
        { project.thumbnails.map((image, index) =>
          <img src={image} className={'aspect-square object-contain h-32 w-32 mr-2 border-2 ' + (project.selectedImageIndex === index ? 'border-gray-800' : '')} alt='thumbnail' onClick={ () => { onImageClick(index) } }/>
          ) }
      </div>
    </div>
  )
}

export default ProjectGallery