import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Cv from "./routes/Cv";
import Portfolio from './routes/Portfolio';

import HardwareExperience from "./routes/HardwareExperience";
import Contact from "./routes/Contact";
import PcbPortfolio from "./routes/PcbPortfolio";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Cv />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/pcb-portfolio" element={<PcbPortfolio />} />
        <Route path="/hardware-experience" element={<HardwareExperience />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
