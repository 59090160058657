import React from 'react'

import { FaArrowLeft, FaArrowRight, FaCircle } from 'react-icons/fa'

const Project = ({project, onProjectClicked, onSelectedImageChanged, onTopicClicked, selectedTopic}) => {

  const leftArrowOnClick = (project) => {
    console.log(project.images.length);

    if(project.selectedImageIndex > 0) {
      project.selectedImageIndex = project.selectedImageIndex - 1;
    } else {
      project.selectedImageIndex = project.images.length - 1;
    }

    onSelectedImageChanged(project.id, project.selectedImageIndex);
  }

  const rightArrowOnClick = (project) => {
    console.log(project.images.length);

    if(project.selectedImageIndex < project.images.length - 1) {
      project.selectedImageIndex  = project.selectedImageIndex + 1;
    } else {
      project.selectedImageIndex = 0;
    }

    onSelectedImageChanged(project.id, project.selectedImageIndex);
  }
  
  return (
        <div className={'flex flex-col project rounded-lg border-gray-200 border-2 hover:border-gray-900 p-2 m-2'}  onAnimationEnd={((e) => { console.log("Ended") }) }>
            <h2 className='font-bold text-center text-xl' onClick={() => { onProjectClicked(project.id) } }>{project.text}</h2>

            <h3 className='text-left text-sm mt-1 px-2 whitespace-pre-line'>{project.description}</h3>

            {
              //Setting min height limits max size
              <div className='flex flex-col grow min-h-0'> 
                <img className='w-full h-full object-contain p-1' src={project.thumbnails[project.selectedImageIndex]} alt="Project" onClick={() => { onProjectClicked(project.id) } }/>
              </div>
            }
            
            <div className='text-center'>
              {project.technologies.map((technology) => 
                <p className={'inline-block rounded p-1 mr-1 mt-1 hover:bg-blue-400 ' + (selectedTopic === technology ? "text-white bg-blue-900" : "text-white bg-blue-500")} onClick={ () => { onTopicClicked(technology) } }>{technology}</p>
              )}
            </div>

            { project.images.length > 1 &&
              <div className='grow-0 text-center p-1'>
                {
                  project.images.length > 1 &&
                  <div className='inline-block bg-blue-500 hover:bg-blue-400 rounded p-1 mr-5'>
                    <FaArrowLeft className='inline-block text-right text-white' size='25' onClick={ () => leftArrowOnClick(project) }/>
                  </div>
                }

                {
                  project.images.map((image, index) => {
                    if(project.selectedImageIndex === index) {
                      return <FaCircle className='inline-block m-1'/>
                    } else {
                      return <FaCircle className='inline-block m-1 text-gray-400'/>
                    }
                  }
                  )
                }

                {
                  project.images.length > 1 &&
                  <div className='inline-block bg-blue-500 hover:bg-blue-400 rounded p-1 ml-5'>
                    <FaArrowRight className='inline-block text-right text-white' size='25' onClick={ () => rightArrowOnClick(project) }/>
                  </div>
                }
              </div>
            }
          
    </div>
  )
}

export default Project