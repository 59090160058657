import React from 'react'

import Layout from '../Layout'

import { Link } from 'react-router-dom'

const Cv = () => {

  document.title = "Jonathan Pearson - CV";

  return (
    <Layout>
      <h2 className='text-xl font-bold'>About</h2>
      <p className='pt-1'>
        Hi I am a firmware/software developer. Who has expertise in a wide range of technologies from firmware to web and mobile apps.<br/>
      </p>
      <p className='pt-5'>
        Current living in Napier, New Zealand.
      </p>

      <div className='pt-5'>
        <h2 className='text-xl font-bold'>Language Experience</h2>
        <ul className='pt-1 list-disc list-inside'>
          <li>C/C++</li>
          <li>C#</li>
          <li>VB.NET</li>
          <li>PHP</li>
          <li>Java</li>
          <li>Javascript</li>
          <li>Go</li>
        </ul>
      </div>

      <div className='pt-5'>
        <h2 className='text-xl font-bold'>Web</h2>
        <ul className='pt-1 list-disc list-inside'>
          <li>HTML+CSS</li>
          <li>React</li>
          <li>Laravel</li>
          <li>ASP Core</li>
        </ul>
      </div>

      <div className='pt-5'>
        <h2 className='text-xl font-bold'>Databases</h2>
        <ul className='pt-1 list-disc list-inside'>
          <li>MySQL/MariaDB</li>
          <li>SQLite</li>
          <li>MSSQL</li>
        </ul>
      </div>

      <div className='mt-10'>
        <Link className='block sm:w-64 sm:inline-block rounded font-bold bg-blue-500 hover:bg-blue-400 text-white text-center p-2' to={'/contact'}>Contact Me</Link>
      </div>

      <div className='pt-10'>
        <h2 className='text-xl font-bold'>Timeline</h2>

        <div className='mt-4 sm:flex bg-gray-200 rounded p-2'>
          <div className='flex flex-col justify-center'>
            <p className='rounded bg-gray-500 text-white px-5 py-2 text-center'>2014</p>
          </div>
          
          <div className='pl-2 flex flex-col justify-center mt-2 sm:mt-0'>
            <p className='font-bold'>Started working at Willowbank Electronics Ltd</p>
          </div>
        </div>

        <div className='mt-4 sm:flex bg-gray-200 rounded p-2'>
          <div className='flex flex-col justify-center'>
            <p className='rounded bg-gray-500 text-white px-5 py-2 text-center'>2011</p>
          </div>
          
          <div className='pl-2 flex flex-col justify-center mt-2 sm:mt-0'>
            <p className='font-bold'>Bachelor of Computing Systems (BCS) at <a href='https://www.eit.ac.nz/' className='underline text-blue-500 hover:text-gray-300'>EIT</a></p>
            <p>With specialisations in web development and programming</p>
          </div>
          
        </div>

        <div className='mt-4 sm:flex bg-gray-200 rounded p-2 '>
          <div className='flex flex-col justify-center'>
            <p className='rounded bg-gray-500 text-white px-5 py-2 text-center'>2010</p>
          </div>
          
          <div className='pl-2 flex flex-col justify-center mt-2 sm:mt-0'>
            <p className='font-bold'>Graduated NBHS</p>
          </div>
        </div>

      </div>

    </Layout>
  )
}

export default Cv