import React, { useEffect, useState } from 'react'

import Nav from '../Nav'
import Footer from '../Footer';

import ProjectList from '../ProjectList';

const Portfolio = () => {
    const [projects, setProjects] = useState(null);

    document.title = "Jonathan Pearson - Portfolio";

    const getPortfolio = async () => {
        var res = await fetch("/pages/portfolio.json")
        var data = await res.json();
        return data;
    }

    useEffect(() => {
        const loadPortfolio = async () => {
            var array = [];
            var bodyArray = {};

            bodyArray.one = "1";
            bodyArray.two = "2";

            array.push(bodyArray);
            array.push(bodyArray);

            console.log(JSON.stringify(array));

            var portfolioFromServer = await getPortfolio();

            //Add id
            portfolioFromServer.forEach((element, index) => {
                element.id = index;
            });

            setProjects(portfolioFromServer);
        }

        loadPortfolio();
    }, []);

  return (
    <div>
        <Nav />

        <div className='p-5'>
            
            { projects != null && 
                <ProjectList projectList={projects} />
            }

        </div>

        <Footer />
    </div>
  )
}

export default Portfolio