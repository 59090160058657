import React from 'react'

import { NavLink } from 'react-router-dom';
import MenuLink from './MenuLink';

const Nav = () => {

  return (
    <nav className='w-full sm:flex sm:items-center sm:flex-wrap bg-gray-800 pt-1 sm:pt-0'>
        <NavLink className={ "sm:flex-shrink-0 font-bold text-xl text-white mx-1 ml-2" } to={'/'}>Jonathan Pearson</NavLink>

        <div className='sm:flex-grow sm:flex sm:flex-wrap'>
          
          <MenuLink to={'/'} title="CV" />
          <MenuLink to={'/portfolio'} title="Software Portfolio" />
          <MenuLink to={'/pcb-portfolio'} title="PCB Portfolio" />

          <MenuLink to={'/contact'} title="Contact Me" />
        </div>
    </nav>
  )
}

export default Nav