import React, { useEffect, useState } from 'react'

import { FaTimes } from 'react-icons/fa'

import Project from './Project'
import ProjectGallery from './ProjectGallery';

const ProjectList = ({projectList}) => {
  var topics = [];

  projectList.forEach((project) => {
      
    project.technologies.forEach((technology) => {
      
      console.log("Tech");
      
      var technologyItem = topics.find(item => item.name === technology)
      if(technologyItem == null) {
        topics.push({ name: technology, count: 1});
      } else {
        technologyItem.count += 1;
      }

    });

  });

  const [projects, setProjects] = useState(projectList);
  const [galleryProjectId, setGalleryProjectId] = useState(-1);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const onProjectClicked = (id) => {
    setGalleryProjectId(id);
  }

  const onSelectedImageChanged = (id, selectedImageIndex) => {
    setProjects(
      projects.map((task) =>
      task.id === id ? { ...task, selectedImageIndex: selectedImageIndex } : task
    ))
  }

  const onTopicClicked = (topic) => {
    if(selectedTopic === topic) {
      setProjects(projectList);
      setSelectedTopic(null);
    } else {
      setProjects(projectList.filter((project) => project.technologies.includes(topic)));
      setSelectedTopic(topic);
    }
    
  }

  return (
    <div>

    { galleryProjectId > -1 &&
    <div className='flex flex-col fixed top-0 left-0 right-0 p-2 w-full h-full glass opacity-100'>
      <div className='opacity-100 w-full'>
       <div className='flex rounded-t-lg bg-blue-500 w-full py-2 px-4'>
        <p className='text-white grow'>{projects.find(project => project.id === galleryProjectId).text}</p>
        <FaTimes className='rounded hover:text-gray-200 text-black' size={28} onClick={() => {setGalleryProjectId(-1)}} />
       </div>
      </div>


        <div className='grow bg-white rounded-b-lg'>
          <ProjectGallery project={projects.find(project => project.id === galleryProjectId)} onSelectedImageChanged={onSelectedImageChanged} />
        </div>
        
    </div>
    }

    {
      topics.map((topic, index) => 
        <button key={index} className={"rounded hover:bg-blue-400 p-2 ml-2 mt-2 m text-sm text-white " + (selectedTopic === topic.name ? "bg-blue-900" : "bg-blue-500")} onClick={() => {onTopicClicked(topic.name)} }>{topic.name} ({topic.count})</button>
      )
    }

    <div className='flex flex-wrap justify-evenly'>
    {
      projects.map((project) =>
      <Project project={project} fullWidth={false} onProjectClicked={onProjectClicked} onSelectedImageChanged={onSelectedImageChanged } onTopicClicked={ onTopicClicked } selectedTopic={selectedTopic} />
      )
    }
    </div>
    

    <div className='grid gap-4 grid-cols-1 w-96 h-96 mt-2'>
        
      {/* {
        projects.map((project, index) =>
          <Project project={project} fullWidth={false} onProjectClicked={onProjectClicked} onSelectedImageChanged={onSelectedImageChanged } onTopicClicked={ onTopicClicked } selectedTopic={selectedTopic} />
        )
      } */}

      </div>

    </div>
  )
}

export default ProjectList